<template>
    <div>
        <div v-if="index > 0">
            <van-divider dashed>家属 {{index}}</van-divider>
            <div class="text-align-r p-r" style="right: 10px;">
                <van-icon name="delete-o" size="20" color="#ccc" @click="deltetJiashu(index)"/>
            </div>
        </div>
        
        <div v-if="index == 0">
            <van-field
                v-model="item.name"
                label="家属姓名"
                name="jiashu_name"
                required
                trigger='onBlur'
                placeholder="家属姓名"
            />
            
            <van-field
                clickable
                required
                name="guanxi"
                :value="item.guanxi"
                label="与家属关系"
                placeholder="点击选择"
            />
            
            <van-field
                v-if="item.guanxi == '其他'"
                v-model="item.guanxi"
                label="与家属关系"
                required
                name="guardianshipFee"
                placeholder="与家属关系"
            />
            
            <van-field
                v-model="item.tel"
                label="联系电话"
                name="phone"
                required
                trigger='onBlur'
                placeholder="联系电话"
            />
        </div>
        <div v-else>
            <van-field
                v-model="item.name"
                label="家属姓名"
                name="jiashu_name"
                required
                trigger='onBlur'
                placeholder="家属姓名"
                :rules="[
                    { 
                        required: true,
                        message: '姓名必填'
                    },
                    {
                        pattern : nameVerification,
                        message: '请填写正确的姓名'
                    }
                ]"
            />
            
            <van-field
                clickable
                required
                name="guanxi"
                :value="item.guanxi"
                label="与家属关系"
                placeholder="点击选择"
                @click="guanxi(true)"
            />
            <van-popup v-model="selected" position="bottom" required>
                <van-picker
                    show-toolbar
                    :columns="guardianshipFee"
                    @confirm="guanxiSelected"
                    @cancel="guanxi(false)"
                />
            </van-popup>
            
            <van-field
                v-if="item.guanxi == '其他'"
                v-model="item.guanxi"
                label="与家属关系"
                required
                name="guardianshipFee"
                placeholder="与家属关系"
                :rules="[{ pattern : nameVerification, message: '请输入正确内容' }]"
            />
            
            <van-field
                v-model="item.tel"
                label="联系电话"
                name="phone"
                required
                trigger='onBlur'
                placeholder="联系电话"
                :rules="[
                    { 
                        required: true,
                        message: '联系电话必填'
                    },
                    {
                        pattern : phoneVerification,
                        message: '请填写正确的手机号码'
                    }
                ]"
            />
        </div>
        
    </div>
</template>

<script>
    export default {
        props:{
            item:{
                type:Object
            },
            index:{
                type:Number,
                default:0
            },
            guardianshipFee:{
                type:Array
            }
        },
        data(){
            return {
                selected:false,
                nameVerification:/^[\u4E00-\u9FA5]{2,20}$/, // 姓名验证
                phoneVerification:/(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/, // 手机号验证
            }
        },
        mounted() {
        },
        methods:{
            deltetJiashu(index){
                this.$emit('deleteJiashu', index)
            },
            guanxiSelected(value){
                let i = this.index
                
                this.selected = false
                this.$emit('guanxiSelected', {
                    value:value,
                    index:i
                })
            },
            guanxi(status){
                this.selected = status
            }
        }
    }
</script>

<style>
</style>
