<template>
	<div>
        <div class="text-align">
            <h1 class="font-25 l-h-40 m-t-20" style="letter-spacing: 1px;">预报名登记</h1>
            <p class="l-h-30" style="color: #666666;">请完善个人信息</p>
        </div>
        
        <div class="m-a" style="width: 65%;padding: 15px 0 20px;">
            <van-steps :active="1">
                <van-step>填写基础信息</van-step>
                <van-step>完善个人信息</van-step>
            </van-steps>
        </div>
		
		<van-form validate-first @failed="onFailed" @submit="formSubmit">
			
			<!-- 通过 pattern 进行正则校验 -->
			<van-field
				v-model="form.name"
				label="姓名"
				name="name"
				trigger='onBlur'
				placeholder="幼儿姓名"
				required
                readonly
                label-width="70"
			/>
            
			<van-field name="sex" label="性别" required label-width="70">
				<template #input>
					<van-radio-group v-model="form.sex" direction="horizontal" disabled>
						<van-radio name="1">男</van-radio>
						<van-radio name="2">女</van-radio>
					</van-radio-group>
				</template>
			</van-field>
            
            <van-field
                readonly
                clickable
                required
                name="minzu"
                :value="form.minzu"
                label="民族"
                placeholder="点击选择"
                label-width="70"
                @click="minzuStatus = true"
            />
            <van-popup v-model="minzuStatus" position="bottom" required>
                <van-picker
                    show-toolbar
                    :columns="minzu"
                    @confirm="mzOnConfirm"
                    @cancel="minzuStatus = false"
                    label-width="70"
                />
            </van-popup>
			
			<van-field
				v-model="form.idCard"
				label="身份证号"
				required
                readonly
				name="idCard"
				placeholder="身份证号"
                label-width="70"
				@blur="blurEvent('idCard')"				
			/>
			
			<van-field required v-if="form.age" label="年龄" :value="form.age" readonly label-width="70"/>
			
			<van-field
				v-model="form.liveAddress"
				is-link
				readonly
				required
				name="liveAddress"
				label="现居地址"
				placeholder="请选择所在地区"
                label-width="70"
				@click="liveAddressStatus = true"
			/>
			<van-popup v-model="liveAddressStatus" round position="bottom">
				<van-area
					title="现居地址"
					@confirm="liveAddress"
					:area-list="areaList"
                    label-width="70"
					:columns-placeholder="['请选择', '请选择', '请选择']"
				/>
			</van-popup>
			
			<van-field
				v-model="form.address"
				label="详细地址"
				required
				name="address"
				placeholder="详细地址"
                label-width="70"
				:rules="[{ required: true, message: '请输入正确的详细地址' }]"
			/>
			
			<van-field
				v-model="form.registerAddress"
				is-link
				readonly
				required
				name="registerAddress"
				label="户籍地址"
				placeholder="请选择所在地区"
                label-width="70"
				@click="registerAddressStatus = true"
			/>
			<van-popup v-model="registerAddressStatus" round position="bottom">
				<van-area
					title="户籍地址"
					@confirm="registerAddress"
					:area-list="areaList"
                    label-width="70"
					:columns-placeholder="['请选择', '请选择', '请选择']"
				/>
			</van-popup>
			
            <van-field
                v-model="form.registerInfo"
                label="详细地址"
                required
                name="registerInfo"
                placeholder="详细地址"
                label-width="70"
                :rules="[{ required: true, message: '请输入正确的详细地址' }]"
            />
            
            <van-field
                v-model="form.jiguan"
                label="籍贯"
                required
                name="jiguan"
                placeholder="籍贯"
                label-width="70"
                :rules="[{ required: true, message: '请输入正确的内容' }]"
            />
            
            <divider-10></divider-10>
            <van-divider>监护人</van-divider>
            
            <div v-for="(item,index) in form.jiashu" :key="index">
                <jiashu-guanxi :item="item" :index="index" :guardianshipFee="guardianshipFee" @guanxiSelected="guanxiSelected" @deleteJiashu="deleteJiashu"></jiashu-guanxi>
            </div>
            
            <div v-if="form.jiashu.length < 4" class="text-align" style="line-height: 60px;">
                <van-button icon="plus" type="primary" size="small" @click.prevent="addJiashu">继续添加</van-button>
            </div>
            
            <divider-10></divider-10>
                        
            <!--  -->
            <van-divider>其他信息</van-divider>			
            <van-field
                v-model="form.beizhu"
                rows="3"
                autosize
                label="备注"
                type="textarea"
                label-width="40"
                placeholder="如有其他备注信息，请填写"
            />
			
			<div style="margin: 16px;">
				<van-button round block type="primary" native-type="submit">提交</van-button>
			</div>
            
		</van-form>
        
	</div>
	
</template>

<script>
	import { areaList } from '@vant/area-data';  // 官方地址库    
    import minzu  from '../../../public/js/minzu.js'    
    import jiashuGuanxi from '../../components/form/jiashu-guanxi.vue'
		
	export default {
        components:{
            jiashuGuanxi
        },
		data() {
			return {
				form:{
					name:'', // 幼儿姓名
					sex:'', // 学生性别
					phone:'', // 学生手机号
					idCard:'', // 身份证号
					age:'', // 年龄
                    minzu:'',
					liveAddress:'', // 现居住地址
					address:'', // 现居住详细地址
					registerAddress:'',  // 户籍地址
					registerInfo:'',  // 户籍详细地址
                    jiguan:'', // 籍贯
                    xuexiao:'', // 毕业学校
                    chengji:{   // 成绩
                        yuwen:'',
                        shuxue:'',
                        english:'',
                        total:''
                    },
                    jiashu:[    // 家属
                        {
                            name:'',
                            guanxi:'',
                            tel:''
                        }
                    ],
                    techang:'',   // 特长
                    beizhu:''   // 其他备注
				},
                minzu:minzu.list,
                guanxi:[
                    {
                        status:false
                    }
                ],
                minzuStatus:false, // 民族选项
				guardianStatus:false,  // 监护人选项
				contactTypeStatus:false, // 联系方式选项
				liveAddressStatus:false, // 居住地址选项
				registerAddressStatus: false, // 户籍地址选择 
				fieldValue: '',
				areaList, // 官方地址库

				guardianshipFee: ['父亲','母亲','爷爷','奶奶','外婆','外公'],
            }
		},
        mounted() {            
            let data = this.$storage.getItem('studentForm')            
            if(!data){
                this.$router.go(-1);
                return false
            }
            this.form.name = data.name
            this.form.phone = data.phone
            this.form.sex = data.sex
            this.form.idCard = data.idCard
            this.form.jiashu = data.jiashu
            
            this.form.age = this.$computed.getAge(this.form.idCard)            
        },
		methods: {
           
            guanxiSelected(e){
                this.form.jiashu[e.index].guanxi = e.value
            },
            
            // 添加家属
            addJiashu(){
                let jiashu = this.form.jiashu
                let data = {
                    name:'',
                    guanxi:'',
                    tel:''
                }
                jiashu.push(data)
                let guanxi = this.guanxi
                guanxi.push({status:false})
            },
            
            // 删除家属
            deleteJiashu(index){                
                this.$dialog.confirm({
                    title: '',
                    message: '确定要删除吗？',
                })
                .then(() => {
                    console.log('确定删除')
                    let jiashu = this.form.jiashu
                    jiashu.splice(index, 1)
                    this.form.jiashu = jiashu
                })
                .catch(() => {
                    console.log('取消')
                });
            },
            
            mzOnConfirm(value) { // 选择民族
                this.form.minzu = value
                this.minzuStatus = false
            },
            
            chengjiVerification(value){ // 身份证号验证
                return /^[0-9]+(.[0-9]{2})?$/.test(value);
            },
            
			formSubmit(){
				let form = this.form
				if(form.sex == ''){
					this.$toast.fail('请选择性别')
					return false
				}
                
				this.$api.register.addForm(form).then((res) => {
                    if(res.msg){
                        this.$dialog.alert({
                            message: res.msg,
                            theme: 'round-button'
                        }).then(() => {
                            // on close
                            this.$router.push({
                                path:'/'
                            })
                        });
                    }
				})
			},
			onFinish({ selectedOptions }) {
				// 全部选项选择完毕后，会触发 finish 事件
				
				// console.log('ok')
				console.log(selectedOptions)
				
				// this.show = false;
				// this.fieldValue = selectedOptions.map((option) => option.text).join('/');
			},
			onFailed(errorInfo) {
				console.log('failed', errorInfo);
			},
			onClickLeft() {
				this.$router.go(-1);
			},
			liveAddress(value){ // 选择居住地址
				let name = ''
				for(let i=0; i<value.length; i++){
					name+=value[i].name
				}
				this.form.liveAddress = name				
				this.liveAddressStatus = false
			},
			registerAddress(value){ // 选择户籍地址
				let name = ''
				for(let i=0; i<value.length; i++){
					name+=value[i].name
				}
				this.form.registerAddress = name				
				this.registerAddressStatus = false
			},
			// 根据身份证号计算年龄
			getAge(identityCard) {
				var len = (identityCard + "").length;
				if (len == 0) {
					return 0;
				} else {
					if ((len != 15) && (len != 18))//身份证号码只能为15位或18位其它不合法
					{
						return 0;
					}
				}
				var strBirthday = "";
				if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
				{
					strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
				}
				if (len == 15) {
					strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
				}
				//时间字符串里，必须是“/”
				var birthDate = new Date(strBirthday);
				var nowDateTime = new Date();
				var age = nowDateTime.getFullYear() - birthDate.getFullYear();
				//再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
				if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
					age--;
				}
				return age;
			}
		}
	}
</script>

<style>
</style>
