var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.index > 0)?_c('div',[_c('van-divider',{attrs:{"dashed":""}},[_vm._v("家属 "+_vm._s(_vm.index))]),_c('div',{staticClass:"text-align-r p-r",staticStyle:{"right":"10px"}},[_c('van-icon',{attrs:{"name":"delete-o","size":"20","color":"#ccc"},on:{"click":function($event){return _vm.deltetJiashu(_vm.index)}}})],1)],1):_vm._e(),(_vm.index == 0)?_c('div',[_c('van-field',{attrs:{"label":"家属姓名","name":"jiashu_name","required":"","trigger":"onBlur","placeholder":"家属姓名"},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('van-field',{attrs:{"clickable":"","required":"","name":"guanxi","value":_vm.item.guanxi,"label":"与家属关系","placeholder":"点击选择"}}),(_vm.item.guanxi == '其他')?_c('van-field',{attrs:{"label":"与家属关系","required":"","name":"guardianshipFee","placeholder":"与家属关系"},model:{value:(_vm.item.guanxi),callback:function ($$v) {_vm.$set(_vm.item, "guanxi", $$v)},expression:"item.guanxi"}}):_vm._e(),_c('van-field',{attrs:{"label":"联系电话","name":"phone","required":"","trigger":"onBlur","placeholder":"联系电话"},model:{value:(_vm.item.tel),callback:function ($$v) {_vm.$set(_vm.item, "tel", $$v)},expression:"item.tel"}})],1):_c('div',[_c('van-field',{attrs:{"label":"家属姓名","name":"jiashu_name","required":"","trigger":"onBlur","placeholder":"家属姓名","rules":[
                { 
                    required: true,
                    message: '姓名必填'
                },
                {
                    pattern : _vm.nameVerification,
                    message: '请填写正确的姓名'
                }
            ]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('van-field',{attrs:{"clickable":"","required":"","name":"guanxi","value":_vm.item.guanxi,"label":"与家属关系","placeholder":"点击选择"},on:{"click":function($event){return _vm.guanxi(true)}}}),_c('van-popup',{attrs:{"position":"bottom","required":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.guardianshipFee},on:{"confirm":_vm.guanxiSelected,"cancel":function($event){return _vm.guanxi(false)}}})],1),(_vm.item.guanxi == '其他')?_c('van-field',{attrs:{"label":"与家属关系","required":"","name":"guardianshipFee","placeholder":"与家属关系","rules":[{ pattern : _vm.nameVerification, message: '请输入正确内容' }]},model:{value:(_vm.item.guanxi),callback:function ($$v) {_vm.$set(_vm.item, "guanxi", $$v)},expression:"item.guanxi"}}):_vm._e(),_c('van-field',{attrs:{"label":"联系电话","name":"phone","required":"","trigger":"onBlur","placeholder":"联系电话","rules":[
                { 
                    required: true,
                    message: '联系电话必填'
                },
                {
                    pattern : _vm.phoneVerification,
                    message: '请填写正确的手机号码'
                }
            ]},model:{value:(_vm.item.tel),callback:function ($$v) {_vm.$set(_vm.item, "tel", $$v)},expression:"item.tel"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }